import Uploader from '../utils/uploader.js'
import uuidv1 from 'uuid/v1';

const uploadFile = (file, normalizedId) => {
  const input = document.querySelector('input[type=file]')
  const url = input.dataset.directUploadUrl
  const uploader = new Uploader(file, url, normalizedId);
  uploader.execute(input.name);
}

$(document).on('click', '.item-remove', function (e) {
  e.preventDefault();
  e.stopImmediatePropagation();

  const parentElement = $(this).parent('.preview--item');
  $('#hiddenfield' + parentElement.attr('id')).remove();
  parentElement.remove();
})


$(document).on('click tap', '.direct-upload', function (e) {
  e.preventDefault();
  $('#campaign_heroimage').val($(this).data('blob'));
  $('.campaign--heroimage').removeClass('campaign--heroimage');
  $(this).addClass('campaign--heroimage');
  $(this).addClass('campaign--heroimage-' + lang);
})


$(document).on('change', '.file', function(e) {
  const files = [...this.files].slice(0, 5 - $('.preview--item').length)
  $(this).val(null);
  files.forEach(file => {
    const reader = new FileReader();
    const stroke = 2;
    const radius = 12;
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const normalizedId = "ID-" + uuidv1();

    reader.onload = (e) => {
      console.log(("onlone"))
      $('.upload--area').prepend(`
        <div class="preview--item" id="${normalizedId}">
          <img src="${e.target.result}" />
          <div class="item--progress">
            <svg height="${radius * 2}" width="${radius * 2}">
              <circle
                stroke="white"
                stroke-dasharray="${circumference}"
                style="stroke-dashoffset:${circumference}"
                stroke-width="${stroke}"
                fill="transparent"
                r="${normalizedRadius}"
                cx="${radius}"
                cy="${radius}"
              />
            </svg>
          </div>
        </div>
      `);
    }
    reader.readAsDataURL(file);
    uploadFile(file, normalizedId);
  });
});
