$(document).ready(() =>{
  if ($('#applyform').length > 0) {
    $('body').scrollspy({ target: '#applyform', offset: 116 + $('.navbar')[0].offsetHeight})
  }
});

$(document).on('click', '.scroll-link', function (e) {
  e.preventDefault();
  const sectionTo = $(this).attr('href');
  $('html, body').animate({
    scrollTop: $(sectionTo).offset().top - (104 + $('.navbar')[0].offsetHeight)
  })
});
