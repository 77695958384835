require("@rails/ujs").start()
require("@rails/activestorage").start()
require("bootstrap")
require("trix")
require("@rails/actiontext")

// add custom scripts
import "application/hamburger"
import "application/faq"
import "application/scrollspy"
import "application/charcount"
import "application/direct_upload"
