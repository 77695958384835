$(document).ready(() => {
  $('input[maxlength], textarea[maxlength]').each(function () {
    const elm = $(this);
    const maxLength = elm.attr('maxLength');
    const currLength = elm.val().length;
    elm.siblings('.form-text').html((i, html) => {
      return html.replace(/\d+/g, maxLength - currLength);
    });
  });
})

$(document).on('keyup', 'input[maxlength], textarea[maxlength]', function () {
  const elm = $(this);
  const currLength = elm.val().length;
  const maxLength = elm.attr('maxLength');
  elm.siblings('.form-text').html((i, html) => {
    return html.replace(/\d+/g, maxLength - currLength);
  });
});
