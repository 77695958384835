import { DirectUpload } from "@rails/activestorage"

export default class Uploader {

  constructor(file, url, normalizedId) {
    this.normalizedId = normalizedId;
    this.file = file;
    this.upload = new DirectUpload(file, url, this)
  }

  execute(input_name) {
    this.upload.create((error, blob) => {
      if (error) {
        console.log("error", error)
        $(`#${this.normalizedId}`).remove();
      } else {
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.setAttribute("id", "hiddenfield" + this.normalizedId);
        hiddenField.name = input_name
        document.querySelector('form').appendChild(hiddenField)
        const element = $("#" + this.normalizedId);
        element.data('blob', blob.key);
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", event => this.directUploadDidProgress(event))
  }

  directUploadDidFinish() {
    const file = this.file;
    const element = $("#" + this.normalizedId);
    if ($('.direct-upload').length == 4) {
      $('.max--picture-info').show();
      $('.image-upload').hide();
    }
    element.find('.item--progress').replaceWith(`
      <span class="item-remove" />
    `);
  }

  directUploadDidProgress(event) {
    const progress = ((event.loaded / event.total) * 100).toFixed(1)
    const file = this.file;
    const element = $("#" + this.normalizedId);
    const stroke = 2;
    const radius = 12;
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const offset = circumference - (progress / 100 * circumference);
    element.find('circle').css('strokeDashoffset', offset);

    if (progress >= 100) {
      window.setTimeout(() => {
        this.directUploadDidFinish();
      }, 1000);
    }
  }
}
